// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.close-button {
    color: green;
    font-weight: bolder;
    font-size: 25px;
    cursor: pointer;
    display: flex; 
    justify-content: flex-end; 
}

.report {
    height: 510px;
    width: 1050px;
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,mBAAmB;IACnB,eAAe;IACf,eAAe;IACf,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,aAAa;AACjB","sourcesContent":[".close-button {\n    color: green;\n    font-weight: bolder;\n    font-size: 25px;\n    cursor: pointer;\n    display: flex; \n    justify-content: flex-end; \n}\n\n.report {\n    height: 510px;\n    width: 1050px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
