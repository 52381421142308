import React, { useState, useEffect, useMemo } from "react";
import "./dashboard.css";
import Box from "@mui/material/Box";
import { TableauEmbed } from "@stoddabr/react-tableau-embed-live";

const Dashboard = ({ parentDataRef }) => {
  const importcode = parentDataRef.storeId;
  const url = "https://prod-useast-a.online.tableau.com/t/711/views/OPISHistoryDTCVsPerformance/OPISHistory?&SEI=" + importcode + "&:showVizHome=no&:embed=true&:alerts=no&:subscriptions=no" ;
  console.log(url);
  const onCloseIcon = () => {
    parentDataRef.closeDialog();
  };
  return (
    <div>
      <div
          className="close-button fas fa-lg fa-times-circle"
          title="Close"
          onClick={onCloseIcon}
        />
      <Box sx={{ p: 0 }}>
      <div className="report">
          <iframe 
          id="volumePerformance"
          height="510" 
          scrolling="no" 
          src={url}
          width="1050"></iframe>
        </div>
      </Box>
    </div>
  );
}
export default Dashboard;
